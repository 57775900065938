import type {GetServerSideProps} from 'next'
import type {
  MainPageHydrationMapDataKeys,
  MainPageHydrationMapDataValue,
  MainPageProperties,
} from '@slices/main/lib/types'
import {api} from '@api/index'
import {andThen, call, pipeWith} from 'ramda'
import {checkUser} from '@/library/utils/api/user-authorization'
import {serverHeaders} from '@/library/utils/http/headers'
import type {ApplicationProperties} from '@/types/ui'
import {isPromiseFulfilledResult} from '@utils/guards/promise'

export const getMainPageProperties: GetServerSideProps<
  MainPageProperties
> = async ({req, res}) => {
  const headers = serverHeaders(req.headers, req.cookies)

  return call(
    pipeWith(andThen, [
      () => checkUser(headers),
      async (properties: ApplicationProperties) => {
        try {
          const getPopularCityDestinationsRequest = () =>
            api.destination.getPopularCityDestinations({headers})

          const [popularCityDestinations] = await Promise.allSettled([
            getPopularCityDestinationsRequest(),
          ])

          const hydrationData = new Map<
            MainPageHydrationMapDataKeys,
            MainPageHydrationMapDataValue
          >()

          if (
            isPromiseFulfilledResult(popularCityDestinations) &&
            popularCityDestinations.value
          ) {
            hydrationData.set(
              'popularCityDestinations',
              popularCityDestinations.value,
            )
          }

          return {
            props: {
              hydrationData: {
                ...properties,
                ...Object.fromEntries(hydrationData),
              },
            },
          }
        } catch {
          return {
            props: {
              hydrationData: {
                ...properties,
              },
            },
          }
        }
      },
    ]),
  )
}
