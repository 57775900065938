import {MainPageContent, OrganizationLdScript} from '@slices/main'
import {SeoProvider} from 'library/providers/seo'
import useTranslation from 'next-translate/useTranslation'
import {type FC} from 'react'
import type {MainPageProperties} from '@slices/main/lib/types'
import {Header} from '@/library/components/features'
import {ENVIRONMENT} from '@utils/guards/environment'

const HomePage: FC<MainPageProperties> = () => {
  const {t} = useTranslation('main')

  return (
    <SeoProvider
      config={{
        title: t('seo.title', {
          year: new Date().getFullYear(),
        }),
        description: t('seo.description'),
        author: t('seo.author'),
        keywords: t('seo.keywords'),
        canonical: `${ENVIRONMENT.ORIGIN_SITE_DOMAIN}`,
      }}
      schemaNode={<OrganizationLdScript />}
    >
      <Header />
      <MainPageContent />
    </SeoProvider>
  )
}

export default HomePage

export {getMainPageProperties as getServerSideProps} from '@slices/main'
