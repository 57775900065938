import styled from '@emotion/styled'
import Link from 'next/link'
import {Text} from '@/library/components/ui'
import type {FC} from 'react'
import {useTheme} from '@emotion/react'
import {Icon} from '@/library/components/ui/icon'
import useTranslation from 'next-translate/useTranslation'
import {outputDateFormatter} from '@/library/utils/date/formatting'
import {HOTEL_RATING_FRACTION_DIGITS} from '@/library/constants/hotel'
import type {SearchHistory} from '../lib/types'

const ContentWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: center;

  gap: 4px;

  min-width: 100%;
  height: 100%;
  padding: 20px 20px;
  cursor: pointer;
`

const StyledText = styled(Text)`
  text-shadow: ${({theme}) => theme.palette.textPrimary} 1px 0 15px;
`

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

interface SearchHistoryItemProps {
  searchHistoryItem: SearchHistory[number]
}

export const SearchHistoryItem: FC<SearchHistoryItemProps> = ({
  searchHistoryItem,
}) => {
  const theme = useTheme()
  const {t} = useTranslation('features.search-history')

  if (!searchHistoryItem?.hotelItem?.seoUrl) {
    return null
  }

  return (
    <ContentWrapper href={searchHistoryItem.hotelItem.seoUrl.url}>
      <StyledText size="boldM" color={theme.palette.textQuaternary}>
        {searchHistoryItem.hotelItem.name}
      </StyledText>
      <RatingWrapper>
        {!!searchHistoryItem.hotelItem.rating && (
          <>
            <Icon
              width={14}
              height={14}
              name="like_fill"
              color={theme.palette.textQuaternary}
            />
            <StyledText size="S" color={theme.palette.textQuaternary}>
              {searchHistoryItem.hotelItem.rating.toFixed(
                HOTEL_RATING_FRACTION_DIGITS,
              )}
            </StyledText>
          </>
        )}

        {!!searchHistoryItem.hotelItem.reviews_count && (
          <StyledText size="S" color={theme.palette.textQuaternary}>
            {t('reviews.label', {
              count: searchHistoryItem.hotelItem.reviews_count,
            })}
          </StyledText>
        )}
      </RatingWrapper>

      <StyledText size="S" color={theme.palette.textQuaternary}>
        {outputDateFormatter(new Date(searchHistoryItem.searchedAt))}
      </StyledText>
    </ContentWrapper>
  )
}
