import styled from '@emotion/styled'
import type {FC} from 'react'
import {Card, CarouselItem} from '@ui/index'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useLocalObservable} from 'mobx-react-lite'
import {observer} from 'mobx-react'
import type {TitleType} from '@ui/typography/title'
import {CarouselWrapper} from '../carousel-wrapper'
import {NUMBER_OF_SKELETONS} from '../../lib/constants'
import {SearchHistoryItem} from '../search-history-item'
import {ItemSkeleton} from '../item-skeleton'
import {selectSearchHistory} from '../../lib/selectors'

const StyledCarouselWrapper = styled(CarouselWrapper)`
  margin-bottom: 69px;
`

const StyledCarouselItem = styled(CarouselItem)`
  flex-basis: fit-content;

  :not(:first-of-type) {
    padding-left: 20px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding-left: 0px;
    width: 100%;
    flex-basis: 100%;
  }
`

interface SearchHistoryItemTabletSkeletonProps {
  className?: string
}

const SearchHistoryItemTouchSkeleton: FC<
  SearchHistoryItemTabletSkeletonProps
> = ({className}) => {
  return (
    <CarouselWrapper className={className}>
      {Array.from({length: NUMBER_OF_SKELETONS}).map((_, index) => (
        <StyledCarouselItem key={`skeleton-search-history-tablet-${index}`}>
          <Card.Container>
            <Card.Content>
              <ItemSkeleton active />
            </Card.Content>
          </Card.Container>
        </StyledCarouselItem>
      ))}
    </CarouselWrapper>
  )
}

interface SearchHistoryTabletProps {
  className?: string
  headlineType?: TitleType
}

export const SearchHistoryTouch: FC<SearchHistoryTabletProps> = observer(
  ({className, headlineType}) => {
    const {isLoading, searchHistory} = useLocalObservable(selectSearchHistory)

    if (isLoading) {
      return <SearchHistoryItemTouchSkeleton className={className} />
    }

    if (!searchHistory) {
      return null
    }

    return (
      <StyledCarouselWrapper className={className} headlineType={headlineType}>
        {searchHistory.map((searchHistoryItem) => (
          <StyledCarouselItem key={searchHistoryItem?.hotelItem.id}>
            <Card.Container
              coverImage={searchHistoryItem?.hotelItem.mainImage?.url}
              linearShadow
            >
              <Card.Content>
                <SearchHistoryItem searchHistoryItem={searchHistoryItem} />
              </Card.Content>
            </Card.Container>
          </StyledCarouselItem>
        ))}
      </StyledCarouselWrapper>
    )
  },
)
