import {useDeviceDetection} from '@hooks/use-device-detection'
import {observer} from 'mobx-react'
import {type FC} from 'react'
import type {TitleType} from '@ui/typography/title'
import {useLocalObservable} from 'mobx-react-lite'
import {isEmpty} from 'ramda'
import {SearchHistoryTouch} from './ui/touch'
import {SearchHistoryDesktop} from './ui/desktop'
import {selectSearchHistory} from './lib/selectors'

interface SearchHistoryProps {
  className?: string
  headlineType?: TitleType
}

export const SearchHistory: FC<SearchHistoryProps> = observer(
  ({className, headlineType}) => {
    const {searchHistory} = useLocalObservable(selectSearchHistory)
    const isTouch = useDeviceDetection('touch')

    if (!searchHistory || isEmpty(searchHistory)) {
      return null
    }

    if (isTouch) {
      return (
        <SearchHistoryTouch className={className} headlineType={headlineType} />
      )
    }

    return (
      <SearchHistoryDesktop className={className} headlineType={headlineType} />
    )
  },
)
