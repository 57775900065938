import {store} from '@/library/store'

export const selectSearchHistory = () => ({
  get searchHistory() {
    return store().search.destination.searchHistory
  },
  get isLoading() {
    return store().search.destination.isLoading
  },
})
