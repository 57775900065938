import {observer} from 'mobx-react'
import {Container} from '@components/layouts'
import {RentHouse} from 'slices/main/ui/rent-house'
import {PopularCityDestinations, SearchInput} from '@components/features'
import styled from '@emotion/styled'
import {Title} from '@ui/typography'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {MainPageSearchPageActions} from '@slices/main/ui/search-actions'
import {SearchHistory} from '@/library/components/features/search-history'

const SearchInputWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 70px;

  h1 {
    margin-bottom: 52px;
  }

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    margin-top: 40px;
    margin-bottom: 50px;
    gap: 20px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    margin-top: 20px;
    margin-bottom: 45px;
    gap: 10px;

    h1 {
      margin-bottom: 26px;
    }
  }
`

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SearchWrapper = styled.div`
  width: 100%;
`

const MainPopularCityDestinations = styled(PopularCityDestinations)`
  margin-bottom: 69px;
`

const Content = () => {
  const {t} = useTranslation('main')

  return (
    <Container>
      <SearchInputWrapper>
        <Title type="h1">{t('search_input.title')}</Title>
        <SearchContainer>
          <SearchWrapper>
            <MainPageSearchPageActions />
            <SearchInput />
          </SearchWrapper>
        </SearchContainer>
      </SearchInputWrapper>
      <MainPopularCityDestinations headlineType="h2" />
      <SearchHistory headlineType="h2" />
      <RentHouse />
    </Container>
  )
}

export default observer(Content)
