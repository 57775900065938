import type {FC} from 'react'
import {Card, Carousel, CarouselContent, CarouselItem} from '@ui/index'
import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useLocalObservable} from 'mobx-react-lite'
import {observer} from 'mobx-react'
import type {TitleType} from '@ui/typography/title'
import {CarouselNext, CarouselPrevious} from '@ui/carousel'
import {ListWrapper} from '../list-wrapper'
import {NUMBER_OF_SKELETONS} from '../../lib/constants'
import {ItemSkeleton} from '../item-skeleton'
import {SearchHistoryItem} from '../search-history-item'
import {selectSearchHistory} from '../../lib/selectors'

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
`

const StyledCarouselItem = styled(CarouselItem)`
  flex-basis: fit-content;

  :not(:first-of-type) {
    padding-left: 20px;
  }
`

const StyledCardContainer = styled(Card.Container)`
  @media ${MEDIA_DEVICE_QUERY.DESKTOP} {
    flex: 0 0 18.7%;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    flex: 0 0 23%;
  }
`

interface SearchHistoryItemDesktopSkeletonProps {
  className?: string
}

const SearchHistoryItemDesktopSkeleton: FC<
  SearchHistoryItemDesktopSkeletonProps
> = ({className}) => {
  return (
    <ListWrapper className={className}>
      {Array.from({length: NUMBER_OF_SKELETONS}).map((_, index) => (
        <StyledCardContainer key={`skeleton-search-history${index}`}>
          <Card.Content>
            <ItemSkeleton active />
          </Card.Content>
        </StyledCardContainer>
      ))}
    </ListWrapper>
  )
}

interface SearchHistoryDesktopProps {
  className?: string
  headlineType?: TitleType
}

export const SearchHistoryDesktop: FC<SearchHistoryDesktopProps> = observer(
  ({className, headlineType}) => {
    const {isLoading, searchHistory} = useLocalObservable(selectSearchHistory)

    if (isLoading) {
      return <SearchHistoryItemDesktopSkeleton className={className} />
    }

    if (!searchHistory) {
      return null
    }

    return (
      <ListWrapper className={className} headlineType={headlineType}>
        <StyledCarousel>
          <CarouselContent>
            {searchHistory.map((searchHistoryItem) => (
              <StyledCarouselItem key={searchHistoryItem?.hotelItem.id}>
                <StyledCardContainer
                  coverImage={searchHistoryItem?.hotelItem.mainImage?.url}
                  linearShadow
                >
                  <Card.Content>
                    <SearchHistoryItem searchHistoryItem={searchHistoryItem} />
                  </Card.Content>
                </StyledCardContainer>
              </StyledCarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext />
          <CarouselPrevious />
        </StyledCarousel>
      </ListWrapper>
    )
  },
)
