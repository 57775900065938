import {store} from '@/library/store'
import {LdScript, buildOrganizationLdProps} from '@/library/utils/seo/schema'
import {observer} from 'mobx-react'
import {ORGANIZATION_LD_SCRIPT_KEY} from '../lib/contants'

export const OrganizationLdScript = observer(() => {
  const {origin} = store().utilities

  if (!origin) {
    return null
  }

  return (
    <LdScript
      scriptKey={ORGANIZATION_LD_SCRIPT_KEY}
      schema={buildOrganizationLdProps({origin})}
    />
  )
})
