import styled from '@emotion/styled'
import {Text, Link} from '@ui/index'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import useTranslation from 'next-translate/useTranslation'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {Illustration} from '@ui/illustration'
import {Title} from '@ui/typography'
import {ENVIRONMENT} from '@/library/utils/guards/environment'

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 60px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    gap: 26px;
  }
`

const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    width: 800px;
    flex-wrap: wrap;
    row-gap: 42px;
  }

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    flex-direction: column;
    row-gap: 28px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 356px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    margin-left: 30px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    margin-left: 0;
    width: 100%;
  }
`

const ItemsTextWrapper = styled.div`
  margin-left: 24px;
  color: ${({theme}) => theme.palette.textPrimary};
`

const ItemsTextTitle = styled(Text)`
  @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
    font-size: 12px;
  }
`

const ItemsTextSubTitle = styled(Text)`
  @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
    font-size: 10px;
  }
`

const StyledLink = styled(Link)`
  text-transform: uppercase;
  padding: 15px 60px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
  }
`

export const RentHouse = () => {
  const {t} = useTranslation('main')
  const isTouch = useDeviceDetection('touch')
  const informationTitleSize = isTouch ? 'boldS' : 'boldM'

  return (
    <Wrapper>
      <Title type="h2">{t('rent_house.title')}</Title>
      <StyledLink
        href={ENVIRONMENT.HOTELIERS_URL}
        target="_blank"
        $variant="primary"
      >
        {t('rent_house.link')}
      </StyledLink>
      <ItemsWrapper>
        <Item>
          <Illustration name="give_key" width={56} height={56} />
          <ItemsTextWrapper>
            <ItemsTextTitle size={informationTitleSize}>
              {t('rent_house.give_key.title')}
            </ItemsTextTitle>
            <ItemsTextSubTitle size="S">
              {t('rent_house.give_key.subtitle')}
            </ItemsTextSubTitle>
          </ItemsTextWrapper>
        </Item>
        <Item>
          <Illustration name="search_money" width={56} height={56} />
          <ItemsTextWrapper>
            <ItemsTextTitle size={informationTitleSize}>
              {t('rent_house.search_money.title')}
            </ItemsTextTitle>
            <ItemsTextSubTitle size="S">
              {t('rent_house.search_money.subtitle')}
            </ItemsTextSubTitle>
          </ItemsTextWrapper>
        </Item>
        <Item>
          <Illustration name="verify" width={56} height={56} />
          <ItemsTextWrapper>
            <ItemsTextTitle size={informationTitleSize}>
              {t('rent_house.verify.title')}
            </ItemsTextTitle>
            <ItemsTextSubTitle size="S">
              {t('rent_house.verify.subtitle')}
            </ItemsTextSubTitle>
          </ItemsTextWrapper>
        </Item>
        <Item>
          <Illustration name="full_time" width={56} height={56} />
          <ItemsTextWrapper>
            <ItemsTextTitle size={informationTitleSize}>
              {t('rent_house.full_time.title')}
            </ItemsTextTitle>
            <ItemsTextSubTitle size="S">
              {t('rent_house.full_time.subtitle')}
            </ItemsTextSubTitle>
          </ItemsTextWrapper>
        </Item>
      </ItemsWrapper>
    </Wrapper>
  )
}
