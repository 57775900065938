import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {services} from '@services/index'
import useTranslation from 'next-translate/useTranslation'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 10px;

  button {
    color: ${({theme}) => theme.palette.textPrimary};
    font-weight: 500;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    gap: 20px;

    button {
      font-size: 14px;
    }
  }
`

const Action = styled(Button)``

export const MainPageSearchPageActions = observer(() => {
  const {t} = useTranslation('main')
  const {isStartSearch} = store().search

  const handleClickPromotion = () => {
    services.pages.global.scenario.search.promotion.startSearchPromotionCity()
  }

  const handleClickBestOffers = () => {
    services.pages.global.scenario.search.promotion.startSearchBestOffers()
  }

  return (
    <Wrapper>
      <Action
        variant="text"
        disabled={isStartSearch}
        onClick={handleClickPromotion}
      >
        {t('actions.promotion.label')}
      </Action>
      <Action
        variant="text"
        disabled={isStartSearch}
        onClick={handleClickBestOffers}
      >
        {t('actions.best_offers.label')}
      </Action>
    </Wrapper>
  )
})
