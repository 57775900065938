import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {Title} from '@/library/components/ui/typography'
import styled from '@emotion/styled'
import type {FC, PropsWithChildren} from 'react'
import {Carousel, CarouselContent} from '@ui/index'
import useTranslation from 'next-translate/useTranslation'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {CarouselNext, CarouselPrevious} from '@/library/components/ui/carousel'
import type {TitleType} from '@ui/typography/title'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 52px;

  height: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    gap: 26px;
  }
`
const StyledCarousel = styled(Carousel)`
  width: 100%;
`

const StyledCarouselContent = styled(CarouselContent)`
  border-radius: 12px;
`

const StyledCarouselPrevious = styled(CarouselPrevious)`
  transform: translateY(-100%);
`
const StyledCarouselNext = styled(CarouselNext)`
  transform: translateY(-100%);
`

interface CarouselWrapperProps {
  className?: string
  headlineType?: TitleType
}

export const CarouselWrapper: FC<PropsWithChildren<CarouselWrapperProps>> = ({
  className,
  children,
  headlineType,
}) => {
  const {t} = useTranslation('features.search-history')
  const isMobile = useDeviceDetection('mobile')

  return (
    <Wrapper className={className}>
      <Title type={headlineType}>{t('title')}</Title>
      <StyledCarousel>
        <StyledCarouselContent>{children}</StyledCarouselContent>
        {isMobile && (
          <>
            <StyledCarouselPrevious />
            <StyledCarouselNext />
          </>
        )}
      </StyledCarousel>
    </Wrapper>
  )
}
